import dayjs from 'dayjs';
import store from '@/store';
import AuthService from '@/services/AuthService';
import CompanyService from '@/services/CompanyService';
import TourService from '@/services/TourService';

const initialState = {
  id: null,
  name: null,
  login: null
};

const state = {
  user: initialState,
  ambiente: '',
  isAdmin: false,
  routes: [],
  tour: []
};

function getUserSelectedCompanyBdgdVersionId() {
  return Number(localStorage.getItem(getUserSelectedCompanyBdgdVersionIdKey()));
}

function setUserSelectedCompanyBdgdVersionId(bdgdVersionId) {
  return localStorage.setItem(getUserSelectedCompanyBdgdVersionIdKey(), bdgdVersionId);
}

function getUserSelectedCompanyBdgdVersionIdKey() {
  const { getCompanyId } = store.getters;
  return `userSelectedCompanyBdgdVersionId:CompanyId:${getCompanyId}`;
}

const getters = {
  getUser: (state) => state.user,
  getUserId: (state) => state.user.id,
  getUserName: (state) => state.user.name,
  getUserLogin: (state) => state.user.login,
  getUserIsAdmin: (state) => state.isAdmin,
  getAmbiente: (state) => state.ambiente,
  getTourInfo: (state) => state.tour,
  getUserSelectedCompanyBdgdVersion: (state) => {
    if (!state.user.id) return null;
    const { 
      getCompanyLatestBdgdVersion, 
      getCompanyBdgdsVersions 
    } = store.getters;
    const userSelectedCompanyBdgdVersionId = getUserSelectedCompanyBdgdVersionId();
    if (userSelectedCompanyBdgdVersionId) {
      const userSelectedCompanyBdgdVersion = getCompanyBdgdsVersions.filter((bdgdVersion) => {
        return Number(bdgdVersion.id) === Number(userSelectedCompanyBdgdVersionId);
      })
      .shift() ?? null;
      return userSelectedCompanyBdgdVersion;
    }
    return getCompanyLatestBdgdVersion;
  },
  getUserSelectedCompanyBdgdVersionTxt: (state, getters) => {
    
    const { getUserSelectedCompanyBdgdVersion } = getters;
    if (!getUserSelectedCompanyBdgdVersion) return null;
    
    const { 
      data_registro: month, 
      versao: version,
      titulo: title 
    } = getUserSelectedCompanyBdgdVersion;
    const monthBr = dayjs(month).format('MM/YYYY');
    
    if (
      title != null && 
      title.trim() !== ''
    ) {
      return `BDGD ${title} :: ${monthBr} v${version}`;
    }

    return `BDGD ${monthBr} v${version}`;
  },
  getRoutesFromCompany: (state, rootState) => {
    
    const companyId = rootState.getCompanyId;
    return state.routes[companyId];
  }
};

const actions = {
  async setUserIsAdmin({ commit }) {
    const isAdmin = await AuthService.userIsAdmin();
    commit('SET_IS_ADMIN', isAdmin);
  },
  setUser({ commit }, value) {
    commit('SET_USER', value);
  },
  setTour({ commit }, value) {
    commit('SET_TOUR', value);
  },
  async setUserSelectedCompanyBdgdVersionId({ commit }, value) {
    setUserSelectedCompanyBdgdVersionId(value);
  },
  async login({ commit }, value) {
    const { login, password, companyId } = value;
    await AuthService.login(login, password, companyId);
    const user = await AuthService.getLoggedUser();
    const ambiente = await AuthService.getAmbienteAplicacao();
    const tourInfo = await TourService.getRelacaoTourUser();
    const routes = await AuthService.getUserPermissions();
    const isAdmin = await AuthService.userIsAdmin();
    commit('SET_USER', user);
    commit('SET_AMBIENTE', ambiente);
    commit('SET_TOUR', tourInfo);
    commit('SET_ROUTES', routes);
    commit('SET_IS_ADMIN', isAdmin);
  },
  async loginOidcCopel({ commit }, value) {
    const { keycloakHost, keycloakRealm, keycloakToken } = value;
    await AuthService.loginOidcCopel(keycloakHost, keycloakRealm, keycloakToken);
    const companies = await CompanyService.getCompanies();
    const user = await AuthService.getLoggedUser();
    const ambiente = await AuthService.getAmbienteAplicacao();
    const tourInfo = await TourService.getRelacaoTourUser();
    const routes = await AuthService.getUserPermissions();
    const isAdmin = await AuthService.userIsAdmin();
    const copelIndex = companies.findIndex(company => company.acronym === 'COPEL');
    const copel = companies[copelIndex];
    commit('SET_SELECTED_COMPANY', copel);
    commit('SET_USER', user);
    commit('SET_AMBIENTE', ambiente);
    commit('SET_TOUR', tourInfo);
    commit('SET_ROUTES', routes);
    commit('SET_IS_ADMIN', isAdmin);
  },
  async logout({ commit }) {
    AuthService.logout();
    commit('SET_USER', initialState);
    commit('SET_ROUTES', []);
    commit('SET_IS_ADMIN', false);
  }
};

const mutations = {
  SET_USER: (state, value) => (state.user = value),
  SET_TOUR: (state, value) => (state.tour = value),
  SET_AMBIENTE: (state, value) => (state.ambiente = value),
  SET_ROUTES: (state, value) => (state.routes = value),
  SET_IS_ADMIN: (state, value) => (state.isAdmin = value)
};

export default {
  state,
  getters,
  actions,
  mutations
};
