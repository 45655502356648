import routes from '@/store/modules/routes';

export default [
  {
    path: '/login',
    component: () => import('@/views/login/Index'),
    children: [
      {
        name: routes.state.appRoutes['LOGIN'],
        path: '',
        component: () => import('@/views/login/Login')
      },
      {
        name: routes.state.appRoutes['LOGIN_OIDC_COPEL'],
        path: 'oidc-copel',
        component: () => import('@/views/login/OidcCopel')
      }
    ]
  }
];
