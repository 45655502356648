const state = {
  appRoutes: {
    ADMINISTRACAO: 'Administração',
    ANALISES_SETORIZADAS_HISTORICO: 'Análises Setorizadas',
    ANALISES_SETORIZADAS_ADICIONAR: 'Análises Setorizadas Adicionar',
    ANALISES_SETORIZADAS_EDITAR: 'Análises Setorizadas Editar',
    ANALISES_SETORIZADAS_VIZUALIZAR_MAPA:
      'Análises Setorizadas Visualizar Mapa',
    CANVAS_HISTORICO: 'Mapas Personalizados',
    CANVAS_EDITAR: 'Mapas Personalizados Editar',
    CANVAS_ADICIONAR: 'Mapas Personalizados Adicionar',
    CANVAS_VISUALIZAR_MAPA: 'Mapas Personalizados Visualizar Mapa',
    CONCILIACOES_AUTOMATICAS_HISTORICO: 'Conciliações Automáticas',
    CONCILIACOES_HISTORICO: 'Conciliações',
    CONCILIACOES_ADICIONAR: 'Conciliações Adicionar',
    CONCILIACOES_ADICIONAR_EXPRESS: 'Conciliações Adicionar Express',
    CONCILIACOES_ADICIONAR_CUSTOM: 'Conciliações Adicionar Custom',
    CONCILIACOES_EDITAR_EXPRESS: 'Conciliações Editar Express',
    CONCILIACOES_EDITAR_CUSTOM: 'Conciliações Editar Custom',
    CONSOLIDADOR_QUANTIDADES_HISTORICO: 'Consolidador de Quantidades',
    CONSOLIDADOR_QUANTIDADES_ADICIONAR: 'Consolidador de Quantidades Adicionar',
    DADOS: 'Dados',
    EMPRESAS_LISTAR: 'Empresas',
    EMPRESAS_EDITAR: 'Empresas Editar',
    EMPRESAS_ADICIONAR: 'Empresas Adicionar',
    EMPRESAS_ENTIDADES_BDGD_OBRIGATORIAS:
      'Empresas Entidades BDGD Obrigatórias',
    AGENTES: 'Agentes',
    EXTENSAO_DE_REDE: 'Extensão de Rede',
    EXTENSAO_DE_REDE_ADICIONAR: 'Extensão de Rede Adicionar',
    EXTENSAO_DE_REDE_EDITAR: 'Extensão de Rede Editar',
    EXTENSAO_GEOGRAFICA: 'Extensão Geográfica',
    PERIODOS_INCREMENTAIS: 'Períodos Incrementais',
    ENTIDADES_GEOGRAFICAS_DA_BDGD_VISUALIZAR_MAPA:
      'Entidades Geográficas da BDGD',
    ENTIDADES_GEOGRAFICAS_COMPARAR_NO_MAPA:
      'Comparar Entidades Geográficas da BDGD',
    EXPURGOS_DE_BDGD_HISTORICO: 'Expurgos de BDGD',
    EXPURGOS_DE_BDGD_ADICIONAR: 'Expurgos de BDGD Adicionar',
    GRUPOS_RELATORIOS_HISTORICO: 'Grupos de Relatórios',
    GRUPOS_RELATORIOS_ADICIONAR: 'Grupos de Relatórios Adicionar',
    GRUPOS_RELATORIOS_EDITAR: 'Grupos de Relatórios Editar',
    HOME: 'Dashboard',
    IMPORTACOES_DE_DADOS: 'Importações de Dados',
    IMPORTACOES_DE_DADOS_ADICIONAR: 'Importações de Dados Adicionar',
    IMPORTACOES_DE_DADOS_LOGS: 'Importações de Dados Logs',
    CARGAS_CSV: 'Cargas de CSV',
    CARGAS_CSV_ADICIONAR: 'Cargas de CSV Adicionar',
    LAYERS_PERSONALIZADOS_HISTORICO: 'Layers Personalizados',
    LAYERS_PERSONALIZADOS_EDITAR: 'Layers Personalizados Editar',
    LAYERS_PERSONALIZADOS_ADICIONAR: 'Layers Personalizados Adicionar',
    LAYERS_PERSONALIZADOS_EDITAR_SIMBOLOGIA:
      'Layers Personalizados Editar Simbologia',
    LAYERS_PERSONALIZADOS_VISUALIZAR_MAPA:
      'Layers Personalizados Visualizar Mapa',
    LOGIN: 'Login',
    LOGIN_OIDC_COPEL: 'Login OpenID Connect COPEL',
    ESQUECEU_SENHA: 'Esqueceu Senha',
    REDEFINIR_SENHA: 'Redefinir Senha',
    LOGS_ACESSOS_USUARIOS_LISTAR: 'Logs de Acessos',
    MAPAS: 'Mapas',
    VIZUALIZAR_PERFIL: 'Meu Perfil',
    LOGOUT: 'Logout',
    PERFIS_USUARIOS_LISTAR: 'Perfis Usuários',
    PERFIS_USUARIOS_EDITAR: 'Perfis Usuários Editar',
    PERFIS_USUARIOS_ADICIONAR: 'Perfis Usuários Adicionar',
    POWER_UPS: 'Power Ups',
    RELATORIOS_MODELOS_HISTORICO: 'Relatórios Modelos',
    RELATORIOS_MODELOS_ADICIONAR: 'Relatórios Modelos Adicionar',
    RELATORIOS_MODELOS_EDITAR: 'Relatórios Modelos Editar',
    RELATORIOS_HISTORICO: 'Relatórios',
    RELATORIOS_EDITAR: 'Relatórios Editar',
    RELATORIOS_ADICIONAR: 'Relatórios Adicionar',
    RELATORIOS_MODELO: 'Relatórios Modelo',
    RELATORIOS_VISUALIZAR: 'Relatórios Visualizar',
    RELATORIOS_CUSTOMIZADOS_ADICIONAR: 'Relatórios Customizados Adicionar',
    RELATORIOS_CUSTOMIZADOS_EDITAR: 'Relatórios Customizados Editar',
    RESTAURAR_BDGD_HISTORICO: 'Restaurar BDGD',
    RESTAURAR_BDGD_ADICIONAR: 'Restaurar BDGD Adicionar',
    SETORIZACOES_HISTORICO: 'Setorizações',
    SETORIZACOES_ADICIONAR: 'Setorizações Adicionar',
    SETORIZACOES_EDITAR: 'Setorizações Editar',
    SETORIZACOES_VISUALIZAR_MAPA: 'Setorizações Visualizar Mapa',
    SIGR_COMPARA_HISTORICO: 'SIG-R Compara',
    SIGR_COMPARA_ADICIONAR: 'SIG-R Compara Adicionar',
    SIGR_COMPARA_EDITAR: 'SIG-R Compara Editar',
    MUTACAO_BDGD_HISTORICO: 'Mutação da BDGD',
    MUTACAO_BDGD_ADICIONAR: 'Mutação da BDGD Adicionar',
    USUARIOS_LISTAR: 'Usuários',
    VISUALIZAR_PERFIL_USUARIO: 'Visualizar Perfil Usuário',
    USUARIOS_EDITAR: 'Usuários Editar',
    USUARIOS_ADICIONAR: 'Usuários Adicionar',
    VERSOES_DA_BDGD_HISTORICO: 'Versões da BDGD',
    GERACAO_DA_BDGD: 'Geração da BDGD',
    EXTRAIR_BDGD_HISTORICO: 'Extrações de BDGD',
    EXTRAIR_BDGD_ADICIONAR: 'Extrações de BDGD Adicionar',
    IMPORTACOES_DE_BDGD: 'Importações de BDGD',
    IMPORTACOES_DE_BDGD_ADICIONAR: 'Importações de BDGD Adicionar',
    IMPORTACOES_DE_BDGD_LOGS: 'Importações de BDGD Logs',
    VALIDACOES_DA_BDGD_HISTORICO: 'Validações da BDGD',
    VALIDACOES_DA_BDGD_ADICIONAR: 'Validações da BDGD Adicionar',
    VALIDACOES_DA_BDGD_STATUS_GERAL: 'Validações da BDGD Status Geral',
    VALIDACOES_DA_BDGD_PAINEL_UNIFICADO: 'Validações da BDGD Painel Unificado',
    VALIDACOES_DA_BDGD_PAINEL_IQ_BDGD: 'Validações da BDGD Painel IQ_BDGD',
    ATUALIZACOES_DE_BDGD_ESCOLHER_TIPO: 'Ajustes de BDGD Escolher Tipo',
    ATUALIZACOES_DE_BDGD: 'Ajustes de BDGD',
    ATUALIZACOES_DE_BDGD_ADICIONAR: 'Ajustes de BDGD Adicionar',
    ATUALIZACOES_DE_BDGD_SQL_ADICIONAR: 'Ajustes de BDGD SQL Adicionar',
    ATUALIZACOES_DE_BDGD_SCRIPT_MODELO_ADICIONAR:
      'Ajustes de BDGD Script Modelo Adicionar',
    EXPORTAR_BDGD_HISTORICO: 'Exportações de BDGD',
    EXPORTAR_BDGD_ADICIONAR: 'Exportações de BDGD Adicionar',
    VISUALIZAR_ENTIDADE: 'Visualizar Entidade',
    GEOPERDAS_SERVIDORES_BANCOS_DADOS_LISTAR:
      'GeoPerdas Servidores Bancos Dados Listar',
    GEOPERDAS_SERVIDORES_BANCOS_DADOS_EDITAR:
      'GeoPerdas Servidores Bancos Dados Editar',
    GEOPERDAS_SERVIDORES_BANCOS_DADOS_ADICIONAR:
      'GeoPerdas Servidores Bancos Dados Adicionar',
    CONECTIVIDADE_E_PERDAS_TECNICAS: 'Conectividade e Perdas Técnicas',
    CARREGAR_BDGD_HISTORICO: 'Carregar BDGD Histórico',
    CARREGAR_BDGD_ADICIONAR: 'Carregar BDGD Adicionar',
    PREENCHER_TABELAS_OPERACIONAIS_HISTORICO: 'Etapa 17 NVAL Histórico',
    PREENCHER_TABELAS_OPERACIONAIS_ADICIONAR: 'Etapa 17 NVAL Adicionar',
    PREENCHER_TABELAS_OPERACIONAIS_ANDAMENTO: 'Etapa 17 NVAL Andamento',
    ORACLE_SERVIDORES_BANCOS_DADOS_LISTAR: 'Oracle',
    ORACLE_SERVIDORES_BANCOS_DADOS_EDITAR:
      'Oracle Servidores Bancos Dados Editar',
    ORACLE_SERVIDORES_BANCOS_DADOS_ADICIONAR:
      'Oracle Servidores Bancos Dados Adicionar',
    TABELAS_X_DISCO: 'Tabelas x Disco',
    PERFIS_USUARIOS_LISTAR: 'Perfis Usuários Listar',
    PERFIS_USUARIOS_EDITAR: 'Perfis Usuários Editar',
    PERFIS_USUARIOS_ADICIONAR: 'Perfis Usuários Adicionar',
    SERVIDORES_EC2_NA_AWS_LISTAR: 'Servidores EC2 na AWS Listar',
    SERVIDORES_RDS_NA_AWS_LISTAR: 'Servidores RDS na AWS Listar',
    SEQUENCIAMENTO_ELETRICO: 'Sequenciamento Elétrico',
    SEQUENCIAMENTO_ELETRICO_ADICIONAR: 'Sequenciamento Elétrico Adicionar',
    SEQUENCIAMENTO_ELETRICO_GERAR_MAPAS: 'Sequenciamento Elétrico Gerar Mapas',
    SEQUENCIAMENTO_ELETRICO_GERAR_MAPAS_ADICIONAR:
      'Sequenciamento Elétrico Gerar Mapas Adicionar',
    MALHA: 'Malha',
    MALHA_ADICIONAR: 'Malha Adicionar',
    FASEAMENTO: 'Faseamento',
    FASEAMENTO_ADICIONAR: 'Faseamento Adicionar',
    PROG_GEOPERDAS_ANEEL: 'ProgGeoPerdas ANEEL',
    PROG_GEOPERDAS_ANEEL_ADICIONAR: 'ProgGeoPerdas ANEEL Adicionar',
    PROG_GEOPERDAS_ANEEL_LOGS: 'ProgGeoPerdas ANEEL Logs',
    VALIDACOES_INDQUAL: 'Validações INDQUAL',
    VALIDACOES_INDQUAL_PAINEL_UNIFICADO: 'Validações INDQUAL Painel Unificado',
    IMPORTACOES_INDQUAL: 'Importações INDQUAL',
    IMPORTACOES_INDQUAL_ADICIONAR: 'Importações INDQUAL Adicionar',
    IMPORTACOES_INDQUAL_LOGS: 'Importações INDQUAL Logs',
    VALIDACOES_SAMP: 'Validações SAMP',
    MONITORAMENTO_EM_TEMPO_REAL: 'Monitoramento em Tempo Real',
    BEACONS_X_EQUIPAMENTOS_LISTAR: 'Beacons x Equipamentos',
    BEACONS_X_EQUIPAMENTOS_ADICIONAR: 'Beacons x Equipamentos Adicionar',
    BEACONS_X_EQUIPAMENTOS_VISUALIZAR_MAPA:
      'Beacons x Equipamentos Visualizar Mapa',
    BEACONS_X_EQUIPAMENTOS_MONITORAR: 'Beacons x Equipamentos Monitorar',
    ARQUIVOS_DE_DADOS: 'Arquivos de Dados',
    ARQUIVOS_DE_DADOS_GRUPOS: 'Grupos de Arquivos',
    ARQUIVOS_DE_DADOS_GRUPOS_ADICIONAR: 'Grupos de Arquivos Adicionar',
    ARQUIVOS_DE_DADOS_GRUPOS_EDITAR: 'Grupos de Arquivos Editar',
    ATUALIZACOES_DDA: 'Ajustes de DDA',
    ATUALIZACOES_DDA_VISUALIZAR: 'Ajustes de DDA Visualizar Tabela',
    ATUALIZACOES_MCPSE: 'Ajustes de MCPSE',
    ATUALIZACOES_MCPSE_VISUALIZAR: 'Ajustes de MCPSE Visualizar Tabela',
    ATUALIZACOES_MCPSE_VISUALIZAR: 'Ajustes de MCPSE Visualizar Tabela',
    EXTRACOES_GEOPERDAS: 'Extrações GeoPerdas'
  }
};

export default {
  state
};
