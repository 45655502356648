<template>
  <router-view />
</template>

<script>
import Highcharts from 'highcharts';
import Drilldown from 'highcharts/modules/drilldown';
import More from 'highcharts/highcharts-more';
import SolidGauge from 'highcharts/modules/solid-gauge';

Drilldown(Highcharts);
More(Highcharts);
SolidGauge(Highcharts);

Highcharts.setOptions({
  credits: {
    enabled: false
  },
  lang: {
    thousandsSep: '.',
    decimalPoint: ','
  }
});

export default {
  name: 'App',
  mounted() {
    this.setAppTitle();
  },
  computed: {
    appTitle() {
      return this.$store.getters.getAppTitle;
    }
  },
  methods: {
    setAppTitle() {
      document.title = this.appTitle;
    }
  },
  watch: {
    appTitle() {
      this.setAppTitle();
    }
  }
};
</script>

<style>
@font-face {
  font-family: 'Gotham Rounded';
  src:
    url('./fonts/GothamRounded/GothamRounded-Bold.woff2') format('woff2'),
    url('./fonts/GothamRounded/GothamRounded-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham Rounded';
  src:
    url('./fonts/GothamRounded/GothamRounded-Medium.woff2') format('woff2'),
    url('./fonts/GothamRounded/GothamRounded-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham Rounded';
  src:
    url('./fonts/GothamRounded/GothamRounded-Light.woff2') format('woff2'),
    url('./fonts/GothamRounded/GothamRounded-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
</style>
