import routes from '@/store/modules/routes';

export default [
  {
    path: '/perdas-tecnicas/extracoes-geo-perdas',
    component: () => import('@/views/Index'),
    children: [
      {
        name: routes.state.appRoutes['EXTRACOES_GEOPERDAS'],
        path: 'index',
        component: () =>
          import('@/views/perdas-tecnicas/extracoes-geo-perdas/Index.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            {
              name: routes.state.appRoutes['CONECTIVIDADE_E_PERDAS_TECNICAS'],
              path: '/perdas-tecnicas'
            },
            { name: routes.state.appRoutes['EXTRACOES_GEOPERDAS'] }
          ]
        }
      }
    ]
  }
];
